@use './variables.scss';

* {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
input, textarea, select {
  -webkit-touch-callout: auto; /* iOS Safari */
    -webkit-user-select: auto; /* Safari */
     -khtml-user-select: auto; /* Konqueror HTML */
       -moz-user-select: auto; /* Old versions of Firefox */
        -ms-user-select: auto; /* Internet Explorer/Edge */
            user-select: auto; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
.visuallyhidden {
  position: absolute;
  width: 1px;
  height: 1px;
  left: 0;
  overflow: hidden;
}